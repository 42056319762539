import React from 'react'

import { graphql } from 'gatsby'
import Img from 'gatsby-image'

import Layout from '../components/layout'
import styles from './index.module.scss'

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "20170425-dimitri-steinel-2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, maxHeight: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image2: file(relativePath: { eq: "osx-desktop.png" }) {
      childImageSharp {
        fluid(maxWidth: 800, maxHeight: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

class Article extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      navigationIsFixed: false,
      drawColor: '#333',
      isDrawing: false
    };

    this.canvas = null;
    this.ctx = null;
    this.lastX = 0;
    this.lastY = 0;
  }

  componentDidMount() {
    window.addEventListener('scroll', () => {
      let navigation = document.querySelector('#navigation');

      this.setState({
        navigationIsFixed: window.scrollY > navigation.offsetHeight
      });
    });

    this.drawCanvas();
  }

  drawCanvas() {
    this.canvas = document.querySelector('#draw');
    this.ctx = this.canvas.getContext('2d');

    const clearCnvBtn = document.querySelector('#clear-canvas');
    const randomClrBtn = document.querySelector('#random-colors');
    const downloadBtn = document.querySelector('#download-btn');

    let isFirst = true;
    this.lastX = 0;
    this.lastY = 0;

    this.canvas.width = window.innerWidth;
    this.canvas.height = window.innerHeight;
    this.ctx.lineJoin = 'round';
    this.ctx.lineCap = 'round';
    this.ctx.lineWidth = 7;

    if (isFirst) {
      isFirst = false;
      this._generateColorPalet();
    }

    downloadBtn.addEventListener('click', () => {
      let dataURL = this.canvas.toDataURL('image/png');
      downloadBtn.href = dataURL;
      downloadBtn.download = 'i-love-it-so-much.png';
    });

    clearCnvBtn.addEventListener('click', () => this._onResetCanvas());
    randomClrBtn.addEventListener('click', () => this._onRandomColors());

    this.canvas.addEventListener('mousedown', e => {
      this.setState({ isDrawing: true });
      [this.lastX, this.lastY] = [e.offsetX, e.offsetY];
    });

    this.canvas.addEventListener('mousemove', e =>
      this.state.isDrawing ? this._draw(e) : null
    );
    this.canvas.addEventListener('mouseup', () =>
      this.setState({ isDrawing: false })
    );
    this.canvas.addEventListener('mouseout', () =>
      this.setState({ isDrawing: false })
    );
  }

  _onResetCanvas() {
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height); // clear canvas
  }

  _onRandomColors() {
    let colorPicker = document.querySelectorAll('#color-picker li');

    colorPicker.forEach(picker => {
      let color = '#' + Math.floor(Math.random() * 16777215).toString(16);
      picker.style.backgroundColor = color;
      picker.dataset.color = color;
    });
  }

  _generateColorPalet() {
    const colorPaletAmount = 10;

    for (var i = 0; i < colorPaletAmount; i++) {
      let node = document.createElement('li');
      const wrapper = document.querySelector('#color-picker ul');
      node.addEventListener('click', e => this._onColorPick(e));

      wrapper.appendChild(node);
    }

    this._onRandomColors();
  }

  _onColorPick(e) {
    this.setState({ drawColor: e.target.dataset.color });
  }

  _draw(e) {
    this.ctx.strokeStyle = `${this.state.drawColor}`;
    this.ctx.beginPath();

    // start from
    this.ctx.moveTo(this.lastX, this.lastY);

    // go to
    this.ctx.lineTo(e.offsetX, e.offsetY);
    this.ctx.stroke();

    [this.lastX, this.lastY] = [e.offsetX, e.offsetY];
  }

  render() {
    return (
      <Layout>
        <nav
          className={`${styles.navigation} ${
            this.state.navigationIsFixed ? styles.isFixed : ''
          }`}
          id="navigation"
        >
          <ul>
            <li>
              <a href="/">Dimitri Steinel</a>
            </li>
            <li>
              <ul>
                <li>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://github.com/dsteinel/"
                  >
                    Github
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.instagram.com/dimitri.steinel/"
                  >
                    Instagram
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="http://codepen.io/dSteinel/pens/public/"
                  >
                    Codepen
                  </a>
                </li>
              </ul>
            </li>
            <li>
              mail:{' '}
              <a href="mailTo:dimitri.steinel@gmail.com" title="Write a mail">
                Dimitri.Steinel@gmail.com
              </a>
            </li>
          </ul>
        </nav>
        <main>
          <section
            className={`${styles.hey} ${
              this.state.isDrawing ? styles.isDrawing : ''
            }`}
            id="hey"
          >
            <div className={styles.colorPicker} id="color-picker">
              <button className={styles.randomColors} id="random-colors">
                Random Colors
              </button>
              <ul> </ul>
              <button className={styles.clearCanvas} id="clear-canvas">
                Clear Canvas
              </button>
              <a
                className={styles.downloadBtn}
                id="download-btn"
                title="download the canvas image"
              >
                Download
              </a>
            </div>
            <canvas id="draw" width="1" height="1"></canvas>
            <h1>
              Call me
              <br />
              Timmi
            </h1>
          </section>
          <section className={`${styles.content} ${styles.aboutMe}`} id="me">
            <Img
              className={styles.aboutMeImage}
              fluid={this.props.data.image1.childImageSharp.fluid}
            />
            <div className={styles.abstract}>
              <h2>German creative frontend developer based in the beautiful Allgäu</h2>
              <p>
                I am a former sports teacher and gym trainer. I am a former
                freelance photographer, designer, and interactive media artist.
                I am a former Bar owner and Edenspiekermann employee.
              </p>
              <p>
                <strong>
                  I am a studied Interaction Designer and a computer guy who
                  loves doing various coding things.
                </strong>
              </p>
              <p>
                Frontend Development is not my only passion. I like to build
                interactive things and work with Arduino as well as programming
                a website. This variety let me see projects from a different
                perspective. I really like beeing in teams in which every member
                works very close together.
              </p>
            </div>
          </section>
          <section className={`${styles.content} ${styles.bio}`}>
            <ul>
              <li>
                <h3>Clients I worked with</h3>
                <ul>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="http://fursr.com/"
                    >
                      ///////FUR
                    </a>
                  </li>
                  <li>Adidas</li>
                  <li>Aperto</li>
                  <li>Bosch</li>
                  <li>
                    {' '}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://brauchbarkeit.de/"
                    >
                      Büro für Brauchbarkeit
                    </a>
                  </li>
                  <li>Deutsche Bank</li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://seiten.ebay-kleinanzeigen.de/autobewertung/"
                    >
                      Ebay Kleinanzeigen
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://de.escapio.com/"
                    >
                      Escapio
                    </a>
                  </li>
                  <li>Fraunhofer Institut</li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.friday-magazine.ch/"
                    >
                      Friday Magazine
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="http://fursr.com/about/no-pain-no-game-exhibition-tour"
                    >
                      Goethe Institut (many countries)
                    </a>
                  </li>
                  <li>Heinrich Hertz Institut</li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.helios-gesundheit.de/"
                    >
                      Helios Kliniken
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://jochengerz.eu"
                    >
                      Jochen Gerz
                    </a>
                  </li>
                  <li>L'Oreal</li>
                  <li>Red Bull</li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="http://icn.sap.com"
                    >
                      SAP
                    </a>
                  </li>
                  <li>TripsByTips</li>
                  <li>Volkswagen</li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://geheimnis-der-bilder.zdf.de/"
                    >
                      ZDF
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <h3>Tools I ❤️</h3>
                <ul>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.alfredapp.com/"
                    >
                      Alfred
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://airmailapp.com/"
                    >
                      Airmail
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.macbartender.com/"
                    >
                      Bartender
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="http://mizage.com/divvy/"
                    >
                      Divvy
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://aptonic.com/"
                    >
                      Dropzone
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.iterm2.com/"
                    >
                      iTerm
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.grammarly.com/"
                    >
                      Grammarly
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://ohmyz.sh/"
                    >
                      OhMyZsh
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://pasteapp.me/"
                    >
                      Paste
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://spacelauncherapp.com/"
                    >
                      Spacelauncher
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://github.com/denysdovhan/spaceship-prompt"
                    >
                      Spaceship Prompt
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://github.com/wting/autojump"
                    >
                      Terminal Autojump
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://code.visualstudio.com/"
                    >
                      VS Code
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://github.com/zsh-users/zsh-completions"
                    >
                      ZSH Completions
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <h3>In short</h3>
                <ul>
                  <li>
                    '11 - '15
                    <br />
                    B.A. Interaction Design (btk-fh Berlin)
                  </li>
                  <li>~</li>
                  <li>
                    '11 - '16
                    <br />
                    Freelance Interaction Designer
                  </li>
                  <li>~</li>
                  <li>
                    '15 - '17
                    <br />
                    Frontend Developer{' '}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="http://moccu.com"
                    >
                      @moccu Berlin
                    </a>
                  </li>
                  <li>~</li>
                  <li>
										'17 - Feb'21
                    <br />
                    Frontend Developer & Tech Lead{' '}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.edenspiekermann.com/"
                    >
                      @edenspiekermann_ Berlin
                    </a>
                  </li>
                  <li>
										'21 - May'23
                    <br />
                    Frontend Developer <a href="https://www.bsdex.de/de/">Börse Stuttgart Digital Exchange</a>
                  </li>
									<li>~</li>
                  <li>
										'May'23 - today
                    <br />
                    Frontend Lead <a href="https://www.bsdex.de/de/">Börse Stuttgart Digital Exchange</a>
                  </li>
                </ul>
              </li>
            </ul>
          </section>
          {/* <section className={`${styles.content} ${styles.desktop}`}>
            <h3>This is my Desktop</h3>
            <Img
              className={styles.desktopImage}
              fluid={this.props.data.image2.childImageSharp.fluid}
            />
          </section> */}
        </main>
        <footer></footer>
      </Layout>
    );
  }
}

export default Article;
