/* eslint-disable */

import React from 'react';
import Helmet from 'react-helmet';
import styles from './layout.module.scss';

class Layout extends React.Component {
  render() {
    return (
      <>
        <Helmet
          title="Dimitri Steinels web Portfolio"
          meta={[
            {
              name: 'description',
              content: 'Dimitri Steinels web Portfolio'
            },
            { property: 'og:type', content: 'website' },
            {
              name: 'keywords',
              content: 'frontend, developer, berlin, edenspiekermann'
            }
          ]}
        >
          <html lang="en" />
        </Helmet>

        <main>{this.props.children}</main>
      </>
    );
  }
}

export default Layout;
